<template>
    <section id="get-started" class="section">
          <h3 class="section__title">Get Started</h3>
          <p>Hi there! Im <b>Cris Rendoque</b> currently working in Saudi Arabia as a Business/System Solution Analyst. I have extensive experience in IT field specifically in analyzing and designing business solutions tailored to the banking and government sector, with a proven track record of delivering innovative and efficient systems.</p>
          <p>I'm a versatile professional skilled in the following field: System Analysis and Design, Fullstack Web Development & Cybersecurity from Philippines.</p>
          
          <table id="expertise">
            <tr>
              <td>System Analysis and Design</td>
              <td>Software Architecture, Database Design & Optimization, DevOps & CI/CD Pipelines</td>
            </tr>
            <tr>
              <td>FullStack Web Developer</td>
              <td>Reactjs, Vuejs, Laravel, NodeJs, ExpressJs, NextJs, MongoDB, PostgreSQL, Supabase & Redis, Kafka</td>
            </tr>
            <tr>
              <td>Cybersecurity</td>
              <td>Incident Response & Forensics, Compliance & Risk Management, SIEM, CIAM</td>
            </tr>
          </table>

          <p class="bold-underline">Some tools I learned: </p>
          
          <div class="tech-logos">
            <img src="https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg" alt="React" />
            <img src="https://upload.wikimedia.org/wikipedia/commons/9/95/Vue.js_Logo_2.svg" alt="Vue.js" />
            <img src="https://upload.wikimedia.org/wikipedia/commons/3/35/Tux.svg" alt="Linux" />
            <img src="https://upload.wikimedia.org/wikipedia/commons/2/29/Postgresql_elephant.svg" alt="PostgreSQL" />
            <img src="https://upload.wikimedia.org/wikipedia/commons/9/9a/Visual_Studio_Code_1.35_icon.svg" alt="VS Code" />
            <img src="https://upload.wikimedia.org/wikipedia/commons/9/94/Cloudflare_Logo.png" alt="Cloudflare" />
            <img src="https://voyager.postman.com/logo/postman-logo-icon-orange.svg" alt="Postman" />
            <img src="https://upload.wikimedia.org/wikipedia/commons/a/a7/Docker-svgrepo-com.svg" alt="Docker" />
            <img src="https://upload.wikimedia.org/wikipedia/commons/d/df/Wireshark_icon.svg" alt="Wireshark" />
            <!-- Supabase logo svg -->
            <svg width="50" height="50" viewBox="0 0 109 113" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M63.7076 110.284C60.8481 113.885 55.0502 111.912 54.9813 107.314L53.9738 40.0627L99.1935 40.0627C107.384 40.0627 111.952 49.5228 106.859 55.9374L63.7076 110.284Z" fill="url(#paint0_linear)"/>
            <path d="M63.7076 110.284C60.8481 113.885 55.0502 111.912 54.9813 107.314L53.9738 40.0627L99.1935 40.0627C107.384 40.0627 111.952 49.5228 106.859 55.9374L63.7076 110.284Z" fill="url(#paint1_linear)" fill-opacity="0.2"/>
            <path d="M45.317 2.07103C48.1765 -1.53037 53.9745 0.442937 54.0434 5.041L54.4849 72.2922H9.83113C1.64038 72.2922 -2.92775 62.8321 2.1655 56.4175L45.317 2.07103Z" fill="#3ECF8E"/>
            <defs>
            <linearGradient id="paint0_linear" x1="53.9738" y1="54.974" x2="94.1635" y2="71.8295" gradientUnits="userSpaceOnUse">
            <stop stop-color="#249361"/>
            <stop offset="1" stop-color="#3ECF8E"/>
            </linearGradient>
            <linearGradient id="paint1_linear" x1="36.1558" y1="30.578" x2="54.4844" y2="65.0806" gradientUnits="userSpaceOnUse">
            <stop/>
            <stop offset="1" stop-opacity="0"/>
            </linearGradient>
            </defs>
            </svg>

          </div>

          <div class="tech-logos-2">
            <img src="https://upload.wikimedia.org/wikipedia/commons/0/04/CodeIgniter_Logo.svg" alt="CodeIgniter" />
            <img src="https://upload.wikimedia.org/wikipedia/commons/9/93/MongoDB_Logo.svg" alt="MongoDB" />
            <img src="https://www.mysql.com/common/logos/includes-mysql-167x86.png" alt="MySQL" />
            <img src="https://upload.wikimedia.org/wikipedia/commons/d/d9/Node.js_logo.svg" alt="Node.js" />
            <img src="https://upload.wikimedia.org/wikipedia/commons/6/64/Expressjs.png" alt="Express.js" />
            <img src="https://upload.wikimedia.org/wikipedia/commons/8/8e/Nextjs-logo.svg" alt="Next.js" />
            <img src="https://upload.wikimedia.org/wikipedia/commons/5/51/Google_Cloud_logo.svg" alt="GCP" />
            <img src="https://upload.wikimedia.org/wikipedia/commons/5/50/Oracle_logo.svg" alt="oracle db" />
          </div>

          <hr class="hr-styled-line">

          <p>To learn more about me please check the following API endpoint on the next section.</p>

          <h3 class="section__title">Usage</h3>
          <p>To access my profile you can use <b>Axios Library</b> to make HTTP request from nodejs.</p>

            <div class="code__block code__block--notabs">
              <pre class="code code--block">
                <code>
                  const axios = require('axios');

                    let config = {
                        method: 'get',
                        maxBodyLength: Infinity,
                        url: 'https://api.crisrendoque.com/',
                        headers: { }
                    };

                    axios.request(config)
                    .then((response) => {
                        console.log(JSON.stringify(response.data));
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                </code>
              </pre>
            </div>

            <h4>Example Response</h4>
            <div class="code__block code__block--notabs">
              <pre class="code code--block">
                <code>
                  HTTP/1.1 200 OK
                  {
                      "title": "portfolio-api",
                      "version": "1.0",
                      "status": true,
                      "resource": "basic-info",
                      "message": "Basic Information",
                      "data": {..}
                  }
                </code>
              </pre>
            </div>

            <!-- hide table for now
            <table id="customers">
            <tr>
              <th  class="th-header-style">Field</th>
              <th  class="th-header-style">Description</th>
            </tr>
            <tr>
              <td>title</td>
              <td>API title</td>
            </tr>
            <tr>
              <td>version</td>
              <td>The current version of the API</td>
            </tr>
            <tr>
              <td>status</td>
              <td>Status of the request using boolean data type (true or false)</td>
            </tr>
            <tr>
              <td>resource</td>
              <td>Profile section. Basic information, education, career, certificates</td>
            </tr>
            <tr>
              <td>data</td>
              <td>Objects base on the resources selected</td>
            </tr>
          </table>
          -->

        </section>
</template>


<script>
export default {
 name: 'GetStartedSection',
}
</script>
